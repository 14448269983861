.my-table thead tr{
  color: white;
  background-image: url('./images/frame2.png');
  background-size: cover;
  height:fit-content;
}

.my-table tbody tr:hover {
    background-color: #ecebeb;
  }

  .marquee {
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
