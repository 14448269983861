@media print {
    body * {
      visibility: hidden;
    }
    .form-container,
    .form-container * {
      visibility: visible;
    }
    .form-container {
      position: absolute;
      left: 0;
      top: 0;
    }
    #content {
      border: 1px solid black;
      padding: 10px;
    }
  }

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    border-top-color: #1a1a1a;
    animation: spinner 0.6s linear infinite;
  }
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  
  